import './style/global.css'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { BeamGoogleAnalytics } from './components/root/BeamGoogleAnalytics'
import Root from './components/root/Root'
import { ScrollToTop } from './components/root/ScrollToTop'
import { setupLibraries } from './setupLibraries'
import store from './store'

setupLibraries()

// FIXME: Replace `store` with `reduxStore` once its ready.
ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <Router>
        <BeamGoogleAnalytics />
        <ScrollToTop />
        <Root />
      </Router>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)
