export enum ApiKeyLabel {
  storefront = 'Storefront Component API Key',
  oms = 'OMS Integration API Key',
}

export enum ApiKeyType {
  backend = 'backend',
  orderSync = 'orderSync',
}

// API types

export enum DevConsoleEnvironment {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface DevConsoleChainDetailsResponse {
  staging: {
    chainId: number | null
    stores: {
      id: number
      name: string
      nonprofits: {
        id: number
        name: string
      }[]
    }[]
  }
  production?: {
    chainId: number | null
    stores: {
      id: number
      name: string
      nonprofits: {
        id: number
        name: string
      }[]
    }[]
  }
}

export interface FetchChainApiKeysResponse {
  staging: {
    storefrontApiKey: string
    omsApiKey: string
  }
  production: {
    storefrontApiKey: string
    omsApiKey: string
  }
}

export interface RegenerateApiKeyRequestBody {
  environment: DevConsoleEnvironment
  keyType: ApiKeyType
  oldKeyPrefix: string
}

export interface RegenerateApiKeyResponse {
  key: string
}

export interface FetchTransactionsResponseObject {
  orderId?: string | null
  storeId: number
  chainId: number
  nonprofitId: number | null
  currency: string
  timestamp: string
  cartTotal: number | null
  subscriptionId?: string
  status?: string
  isDeleted?: boolean
  includesSubscription: boolean
  isTest?: boolean
}
export interface FetchTransactionsResponse {
  transactions: FetchTransactionsResponseObject[]
}
