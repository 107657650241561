import shareUploadIcon from './shareUploadIcon.png'

export const FileUploadIcon = ({ ...props }) => {
  return (
    <div {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect width="20" height="20" fill="url(#pattern0_2250_18022)" />
        <defs>
          <pattern
            id="pattern0_2250_18022"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1">
            <use xlinkHref="#image0_2250_18022" transform="scale(0.00195312)" />
          </pattern>
          <image id="image0_2250_18022" width="550" height="550" xlinkHref={shareUploadIcon} />
        </defs>
      </svg>
    </div>
  )
}
