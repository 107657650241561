import { useEffect, useState } from 'react'

import { useBeamSelector } from '../../../../hooks'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamExpandableDetails } from '../../../../stories/BeamExpandableDetails'
import { BeamFileUpload } from '../../../../stories/BeamFileUpload'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../../stories/BeamModal'
import { BeamToast } from '../../../../stories/BeamToast'
import { TUser } from '../../../../utils/types'
import { useGtagWithContext } from '../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { fetchCampaignData } from './CampaignPage.api'
import { marketingVaultUrl } from './constants'
import {
  CampaignPromoObject,
  NonprofitFileEntry,
  NonprofitFileEntryOperationType,
} from './promo.types'
import $$ from './social-share-modal.module.css'
import { SocialShareFirstTimeExperienceModal } from './SocialShareFirstTimeExperienceModal'
import {
  deletePromoAsset,
  listAllPromoAssets,
  postPromoAsset,
  uploadPromoAsset,
} from './SocialShareModal.api'

const openMarketingVaultLink = () => {
  window.open(marketingVaultUrl, '_blank')
}

const TRUE_VALUE = '1'
const HAS_SEEN_FIRST_TIME_EXPERIENCE = 'hasSeenFirstTimeExperienceModal'
const HAS_SEEN_SKIP_FOR_NOW = 'hasSeenSkipForNow'

function setLocalStorage(key: string) {
  localStorage.setItem(key, TRUE_VALUE)
}
function getLocalStorage(key: string) {
  return localStorage.getItem(key)
}

export const validateNewFile = (file: File, listOfFiles: string[]) => {
  const permittedFileType = ['image/png']
  const maxFileSizeLimit = 6291456 // 6 MB
  const filename = file.name

  if (!permittedFileType.includes(file.type)) {
    return 'Error: File type is not compatible. Please change file type and try again.'
  }
  if (file.size > maxFileSizeLimit) {
    return 'Error: File is too big. Please resize and try again.'
  }

  if (listOfFiles.includes(filename)) {
    return 'Error: File name is not unique. Please rename and try again.'
  }

  return ''
}

export const SocialShareModal = ({
  promo,
  nonprofit,
  onCloseCallback,
  onContinueHandleExternal = () => {
    return
  },
}: {
  promo?: CampaignPromoObject | null
  nonprofit?: null
  onCloseCallback?: () => void
  onContinueHandleExternal?: () => void
}) => {
  const [currentPromo, setCurrentPromo] = useState(promo)
  const [currentNonprofit] = useState(nonprofit)
  const user = useBeamSelector(({ user }) => user) as TUser
  const [open, setOpen] = useState(false)
  const [operationsAreHappening, setOperationsAreHappening] = useState(false)
  const [listOfNonprofits, setListOfNonprofits] = useState<NonprofitFileEntry[]>([])
  const [loadingList, setLoadingList] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [campaignHasSocialShareAsset, setCampaignHasSocialShareAsset] = useState(false)
  const gtag = useGtagWithContext()

  useEffect(() => {
    ;(async function determineWhatPromoIdToUse() {
      setOpen(true)
      if (!promo) {
        const data = await fetchCampaignData(user.chainId)
        const sortedData = data.currentPromos.sort((a, b) => {
          return b.id - a.id
        })

        if (!sortedData) return 0
        setCurrentPromo(sortedData[0])
      } else {
        setCurrentPromo(promo)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async function fetchListOfPromoAssets() {
      if (currentPromo) {
        setLoadingList(true)
        const response = await listAllPromoAssets(currentPromo.id)
        const hasPromoAssetUrl = response.some(
          promoNonprofit => promoNonprofit.url && promoNonprofit.url.trim() !== ''
        )
        setCampaignHasSocialShareAsset(hasPromoAssetUrl)
        setListOfNonprofits(response)
        setLoadingList(false)
      }
    })()
  }, [currentPromo])

  const updateFormState = (e: any, nonprofitId: number) => {
    setError('')

    gtag('promo_social_share_modal | add_asset')

    const newFile = e.target.files[0]
    const listOfFiles: string[] = []
    for (const entry of listOfNonprofits) {
      if (entry.url) {
        listOfFiles.push(entry.url.replace(/^.*[\\/]/, ''))
      }
    }
    const errorMessage = validateNewFile(newFile, listOfFiles)
    const isThereAnError = !!errorMessage

    const indexOfNonprofit = listOfNonprofits.findIndex(entry => entry.nonprofitId === nonprofitId)

    if (isThereAnError) {
      setError(errorMessage)
      return false
    } else {
      const filename = newFile.name
      listOfNonprofits[indexOfNonprofit].url = filename

      listOfNonprofits[indexOfNonprofit].operation = {
        type: NonprofitFileEntryOperationType.new,
        file: newFile,
      }
      setOperationsAreHappening(true)
      setListOfNonprofits(listOfNonprofits)
    }

    return true
  }

  const deleteFormState = (e: any, nonprofitId: number) => {
    const indexOfNonprofit = listOfNonprofits.findIndex(entry => entry.nonprofitId === nonprofitId)
    listOfNonprofits[indexOfNonprofit].operation = {
      type: NonprofitFileEntryOperationType.remove,
    }
    setOperationsAreHappening(true)
    setListOfNonprofits(listOfNonprofits)
  }

  const resolveFilesInForm = async () => {
    const chainId = user.chainId

    setOperationsAreHappening(false)
    gtag('promo_social_share_modal | upload_asset')

    for (const nonprofit of listOfNonprofits) {
      if (!nonprofit.operation) {
        continue
      }

      if (nonprofit.operation.type === NonprofitFileEntryOperationType.new) {
        const fileObject = nonprofit.operation.file

        if (currentPromo && fileObject) {
          const uploadUrl = await postPromoAsset(
            fileObject.name,
            chainId,
            currentPromo.id,
            nonprofit.nonprofitId
          )

          await uploadPromoAsset(uploadUrl, fileObject)
        }

        if (currentNonprofit && fileObject) {
          //TODO: https://beamimpact.atlassian.net/browse/PPOR-4186
        }
      }

      if (nonprofit.operation.type === NonprofitFileEntryOperationType.remove) {
        if (currentPromo) {
          await deletePromoAsset(currentPromo.id, nonprofit.nonprofitId)
        }
      }
    }

    setOpen(false)
    setSuccess('Campaign is now set up. You can now find it in your campaign management dashboard.')
  }

  const onContinueHandler = () => {
    setLocalStorage(HAS_SEEN_FIRST_TIME_EXPERIENCE)
    if (onContinueHandleExternal) onContinueHandleExternal()
  }

  return (
    <>
      {getLocalStorage(HAS_SEEN_FIRST_TIME_EXPERIENCE) !== TRUE_VALUE && (
        <SocialShareFirstTimeExperienceModal onContinueHandler={onContinueHandler} />
      )}
      {getLocalStorage(HAS_SEEN_FIRST_TIME_EXPERIENCE) === TRUE_VALUE && (
        <BeamModal
          open={open}
          label={''}
          onCloseCallback={() => {
            if (
              getLocalStorage(HAS_SEEN_FIRST_TIME_EXPERIENCE) &&
              !getLocalStorage(HAS_SEEN_SKIP_FOR_NOW)
            ) {
              setLocalStorage(HAS_SEEN_SKIP_FOR_NOW)
            }

            setListOfNonprofits([])

            gtag('promo_social_share_modal | close')

            if (onCloseCallback) onCloseCallback()
          }}
          body={
            <div className={$$.container}>
              <h1 className={$$.modalTitle} slot="label">
                Boost Awareness For Your Campaign!
              </h1>
              <p className={$$.modalDescription}>
                Upload a social share asset for each nonprofit you’re boosting to give customers the
                chance to share the impact they made for {currentPromo?.name}{' '}
              </p>

              <p className={$$.instructions}>Upload A Social Share Asset For Each Nonprofit:</p>

              {!!loadingList && <BeamLoadingIndicator />}
              <div className={$$.listOfNonprofits}>
                {listOfNonprofits.map((nonprofit: NonprofitFileEntry) => {
                  return (
                    <p key={nonprofit.nonprofitId} className={$$.fileUpload}>
                      <span className={$$.nonprofitName}>{nonprofit.nonprofitName}:</span>{' '}
                      <span className={$$.fileUpload}>
                        <BeamFileUpload
                          key={nonprofit.nonprofitId}
                          defaultFileName={nonprofit.url}
                          onChangeHandler={e => updateFormState(e, nonprofit.nonprofitId)}
                          onClearHandler={e => deleteFormState(e, nonprofit.nonprofitId)}
                          clearIconColor="#00357A"
                        />
                      </span>
                    </p>
                  )
                })}
              </div>
              <div className={$$.expandableGuidelines}>
                <BeamExpandableDetails label="Upload Guidelines">
                  <span>These will help your social content have the biggest impact:</span>
                  <ul className={$$.list}>
                    <li>Must be a .png file</li>
                    <li>Must be no larger than 6MB</li>
                    <li>1080 pixels wide by 1920 pixels tall</li>
                    <li>Each social asset must have unique file names</li>
                    <li>Should include your logo and Beam’s logo</li>
                    <li>Should include campaign details (name, dates, boost amount e.g. 3x)</li>
                    <li>Should include the nonprofit name and the goal funded</li>
                  </ul>
                  <br />
                  <span>Want to see some examples?</span>
                  <br />
                  <br />
                  <BeamButton
                    label="Check out our Marketing Vault"
                    variant="basic_blue"
                    className={$$.marketingVaultButton}
                    onClick={() => {
                      gtag('promo_social_share_modal | marketing_drive')
                      openMarketingVaultLink()
                    }}
                  />
                </BeamExpandableDetails>
              </div>
            </div>
          }
          footer={
            <div className={$$.container}>
              <BeamButton
                label={campaignHasSocialShareAsset ? 'Update Social Assets' : 'Add Social Assets'}
                variant="basic"
                disabled={!operationsAreHappening}
                className={$$.button}
                onClick={async () => resolveFilesInForm()}
              />

              {!getLocalStorage(HAS_SEEN_SKIP_FOR_NOW) && (
                <BeamButton
                  label="Skip for now"
                  variant="text"
                  className={$$.skipButton}
                  onClick={() => setOpen(false)}
                />
              )}
            </div>
          }
        />
      )}

      {/* Success Toast */}
      <BeamToast
        open={!!success}
        text={success || ''}
        variant={'success'}
        onClose={() => setSuccess('')}
        duration={1500}
      />

      {/* Error Toast */}
      <BeamToast
        open={!!error}
        text={error || ''}
        variant={'error'}
        onClose={() => setError('')}
        duration={1500}
      />
    </>
  )
}
