import cx from 'classnames'

import { TUser } from '../../../utils/types'
import $$ from '../beam-side-navigation.module.css'
import { BeamSideNavigationLink } from '../BeamSideNavigationLink'

interface UserBadgeProps {
  user: TUser
  pathname: string | null
}

export const BeamUserBadge = ({ user, pathname }: UserBadgeProps) => {
  return (
    <div className={cx($$['nav-block'])}>
      <div
        className={cx(
          $$['beam-navigation--section-header'],
          $$['beam-navigation--user-badge--header']
        )}>
        Account
      </div>
      <div className="flex flex-row items-center">
        <div className="flex items-center justify-center w-12 h-20 ml-4">
          <div>
            <img src={user.partner.logo} />
          </div>
        </div>
        <div className={cx($$['user-badge-links-wrapper'])}>
          <BeamSideNavigationLink
            key={'profile'}
            path={`/profile`}
            itemLabel={'Settings'}
            active={pathname?.startsWith(`/profile`) || pathname?.startsWith(`/paypal-giving-fund`)}
            className={cx($$['user-badge-link'], $$['navLink'])}>
            <span className={$$['beam-navigation--link-label']}>Settings</span>
          </BeamSideNavigationLink>
          <BeamSideNavigationLink
            key={'logout'}
            path={`/logout`}
            itemLabel={'Logout'}
            active={pathname?.startsWith(`/logout`)}
            className={cx($$['user-badge-link'], $$['navLink'])}>
            <span className={$$['beam-navigation--link-label']}>Log Out</span>
          </BeamSideNavigationLink>
        </div>
      </div>
    </div>
  )
}
