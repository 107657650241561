import cx from 'classnames'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { useGtagWithContext } from '../../../components/root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { useBeamSelector } from '../../../hooks'
import { checkIsFullUrl } from '../../../utils/checkIsFullUrl'
import { getCurrentPageTitle } from '../../../utils/getCurrentPageTitle'
import { TSite } from '../../../utils/types'
import $$ from '../beam-side-navigation.module.css'

interface SideNavLinkProps {
  path: string
  children?: ReactNode
  active?: boolean
  itemLabel?: string
  className?: string
}

export const BeamSideNavigationLink = ({
  path,
  active = false,
  children,
  itemLabel,
  className,
}: SideNavLinkProps) => {
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)
  const gtag = useGtagWithContext()
  const isFullUrl = checkIsFullUrl(path)

  const gtagMetadata = {
    siteFilter: siteFilter?.name || null,
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  function gtagEventName(itemName: string) {
    return `${itemName} clicked`
  }

  if (isFullUrl) {
    return (
      <a
        href={path}
        onClick={() => {
          gtag(gtagEventName(`${itemLabel} clicked` || ''), gtagMetadata)
        }}
        className={cx({ [$$.active]: active }, className)}
        rel="noopener noreferrer"
        target="_blank">
        {children}
      </a>
    )
  }

  return (
    <Link
      to={path}
      onClick={() => {
        gtag(gtagEventName(itemLabel || ''), gtagMetadata)
      }}
      className={cx({ [$$.active]: active }, className)}>
      {children}
    </Link>
  )
}
