export interface CampaignPromoObject {
  id: number
  name: string
  promoText?: string | null
  chainId: number
  availableToWholeChain: boolean
  boostAllNonprofits?: boolean
  boostedStoreNonprofitIds: number[]
  startTime: string
  endTime: string
  colorPrimary: string | null
  foregroundColor?: string | null
  multiplier: number
  boostedNonprofits?: string
  socialShareAsset: string
  hasPromoAssetUrl?: boolean
  boostStatus: string
}

export interface CampaignPageBody {
  completedPromos: CampaignPromoObject[]
  completedPromosCount: number
  currentPromos: CampaignPromoObject[]
  currentPromosCount: number
}

export enum NonprofitFileEntryOperationType {
  new = 'new',
  remove = 'remove',
}
export interface NonprofitFileEntryOperation {
  type: NonprofitFileEntryOperationType
  file?: File
}
export interface NonprofitFileEntry {
  nonprofitId: number
  nonprofitName: string
  url: string | null
  operation?: NonprofitFileEntryOperation
}

export enum PromoModal {
  NoModal = 'NoModal',
  BoostANonprofit = 'BoostANonprofit',
  RequestANewNonprofit = 'RequestANewNonprofit',
  DeleteAPromoConfirmation = 'DeleteAPromoConfirmation',

  FirstTimeExperienceSocialShare = 'FirstTimeExperienceSocialShare',
  PromoSocialShareUpload = 'PromoSocialShareUpload',
  NonprofitSocialShareUpload = 'NonprofitSocialShareUpload',
}
