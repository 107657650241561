import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../../../hooks'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { BeamSEO } from '../../../root/BeamSEO'
import { CenteredContent } from '../../../root/CenteredContent'
import { fetchTransactionLog } from '../dev-console-api'
import { FetchTransactionsResponseObject } from '../types'
import { TransactionLogTable } from './components/TransactionLogTable'

const TRANSACTION_LIFECYCLE_DOCS_URL =
  'https://docs.beamimpact.com/docs/oms-integrations/apis/transaction-lifecycle'
interface TransactionsRequestState {
  transactions: FetchTransactionsResponseObject[] | null
  loading: boolean
  error: string | null
}

export const TransactionsPage = () => {
  const user = useBeamSelector(({ user }) => user)
  const chainId = user?.chainId
  const [requestState, setRequestState] = useState<TransactionsRequestState>({
    transactions: null,
    loading: true,
    error: null,
  })

  useEffect(() => {
    setRequestState(prev => ({ ...prev, error: null, loading: true }))
    ;(async () => {
      try {
        const data = await fetchTransactionLog(chainId)
        setRequestState(prev => ({ ...prev, transactions: data.transactions, loading: false }))
      } catch (error: any) {
        setRequestState(prev => ({
          ...prev,
          error: error.message,
          loading: false,
          transactions: null,
        }))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <BeamSEO title={'Transactions Log'} />
      <div className={'bg-charcoal-50 px-5 pb-4 flex flex-col beam--page-content--no-gutter'}>
        <div className={'flex flex-row w-full justify-between items-center'}>
          <div className={'w-2/3'}>
            <div>
              <h1>Transaction Log</h1>
            </div>
            <div>
              Your last 50 staging transactions registered with Beam will appear here. Read more
              about the transaction lifecycle{' '}
              <a href={TRANSACTION_LIFECYCLE_DOCS_URL} target="_blank" rel="noreferrer">
                here
              </a>
              .
            </div>
          </div>

          <div>
            Need help? <Link to={'/contact-support'}>Contact Support</Link>
          </div>
        </div>
      </div>

      <div className={'pt-4'}>
        {requestState.loading ? (
          <CenteredContent>
            <BeamLoadingIndicator />
          </CenteredContent>
        ) : (
          <TransactionLogTable transactions={requestState.transactions || []} />
        )}
      </div>
    </div>
  )
}
