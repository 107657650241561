import { getUserAccessScopes } from './getUserAccessScopes'
import { TUser } from './types'

export function validateCanAccessRoute(user: TUser): boolean {
  const accessScopes = getUserAccessScopes(user.type)

  if (!accessScopes) {
    return true
  }

  return accessScopes.some(path => window.location.pathname.includes(path))
}
