import { createContext, ReactNode, useState } from 'react'

import { BeamToast, BeamToastProps } from '../../../stories/BeamToast/BeamToast'

type NotificationProviderProps = {
  variant: BeamToastProps['variant']
  message: string
}

interface NotificationContextProps {
  notification: NotificationProviderProps | null
  triggerNotification: (notification: NotificationProviderProps) => void
}

export const DevConsoleNotificationsContext = createContext<NotificationContextProps>({
  notification: null,
  triggerNotification: () => null,
})

export const DevConsoleNotificationsContextProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<NotificationProviderProps | null>(null)

  return (
    <DevConsoleNotificationsContext.Provider
      value={{
        notification,
        triggerNotification: setNotification,
      }}>
      <BeamToast
        open={!!notification}
        onClose={() => setNotification(null)}
        variant={notification?.variant || 'success'}
        text={notification?.message || ''}
      />

      {children}
    </DevConsoleNotificationsContext.Provider>
  )
}
