import { capitalize } from 'lodash'
import { useContext, useState } from 'react'

import { useBeamSelector } from '../../../../../hooks'
import { BeamButton } from '../../../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../../../stories/BeamModal'
import { TUser } from '../../../../../utils/types'
import { DevConsoleContext } from '../../data-store'
import { regenerateApiKey } from '../../dev-console-api'
import { DevConsoleNotificationsContext } from '../../dev-console-notifications-context'
import { ApiKeyLabel, RegenerateApiKeyRequestBody } from '../../types'

export type ConfirmationModalDataProp = {
  keyLabel: ApiKeyLabel
  body: RegenerateApiKeyRequestBody
}
interface ModalProps {
  /**
   * Determines whether the modal is open or not
   */
  isOpen: boolean
  /**
   * Function invoked after the modal is closed. Use this to update the state that handles the modal's `isOpen` state.
   */
  effectsAfterClose: (newApiKey?: string) => void
  /**
   * The data necessary for the modal.
   */
  data: ConfirmationModalDataProp | null
}

export const KeyRegenerationConfirmationModal = ({
  isOpen,
  effectsAfterClose,
  data,
}: ModalProps) => {
  const { triggerNotification } = useContext(DevConsoleNotificationsContext)
  const { dispatch } = useContext(DevConsoleContext)
  const [loading, setLoading] = useState<boolean>(false)
  const user: TUser = useBeamSelector(state => state.user)
  const chainId = user.chainId

  async function handleRegenerateRequest() {
    const body = data?.body
    if (!body) return

    setLoading(true)
    try {
      const newKey = await regenerateApiKey(chainId, body)

      if (data?.keyLabel === ApiKeyLabel.oms) {
        const newKeyPrefix = newKey.key.split('.')[0]
        dispatch({
          type: 'set_new_api_key',
          environment: body.environment,
          keyType: body.keyType,
          newKey: `${newKeyPrefix}.**********`,
        })
      } else {
        dispatch({
          type: 'set_new_api_key',
          environment: body.environment,
          keyType: body.keyType,
          newKey: newKey.key,
        })
      }

      triggerNotification({
        variant: 'success',
        message: 'New API key generated! 🎉',
      })
      setLoading(false)
      effectsAfterClose(newKey.key)
    } catch (error: any) {
      triggerNotification({
        variant: 'error',
        message: 'Error: Key did not regenerate. Please try again',
      })
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <BeamModal
      open={isOpen}
      onCloseCallback={() => effectsAfterClose()}
      disableClickOutside={loading}
      label={`Are you sure you want to regenerate your ${capitalize(data?.body.environment)} ${
        data?.keyLabel
      }?`}
      body={
        <div className="text-center mb-12 relative">
          {loading && (
            <div className={'absolute top-0 left-0 right-0 bottom-0 bg-white opacity-75 z-10'}>
              <BeamLoadingIndicator />
            </div>
          )}
          <p className={'text-md mt-8 mb-4 text-cherry-600 italic'}>
            Doing this will revoke your current key.
          </p>
        </div>
      }
      footer={
        <div slot={'footer'} className={'space-y-4 pt-4'}>
          <BeamButton onClick={handleRegenerateRequest} disabled={loading}>
            Yes, Regenerate Key
          </BeamButton>
          <BeamButton variant={'flat_white'} onClick={effectsAfterClose} disabled={loading}>
            No, Cancel
          </BeamButton>
        </div>
      }
    />
  )
}
