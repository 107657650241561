import { createContext, Dispatch, useReducer } from 'react'

import {
  ApiKeyType,
  DevConsoleChainDetailsResponse,
  DevConsoleEnvironment,
  FetchChainApiKeysResponse,
  RegenerateApiKeyResponse,
} from './types'

type DevConsoleAction =
  | { type: 'set_active_tab'; payload: DevConsoleEnvironment }
  | { type: 'fetch_chain_details_init' }
  | {
      type: 'fetch_chain_details_finished'
      payload: DevConsoleChainDetailsResponse | null
      error?: string | null
    }
  | { type: 'fetch_api_keys_init' }
  | {
      type: 'fetch_api_keys_finished'
      payload: FetchChainApiKeysResponse | null
      error?: string | null
    }
  | {
      type: 'set_new_api_key'
      environment: DevConsoleEnvironment
      keyType: ApiKeyType
      newKey: string
    }

interface GenericDataFetchState<ResponseType = any> {
  isLoading: boolean
  data: ResponseType | null
  error: string | null
}
interface DevConsoleState {
  chainDetailsState: GenericDataFetchState<DevConsoleChainDetailsResponse>
  apiKeysState: GenericDataFetchState<FetchChainApiKeysResponse>
  activeTab: DevConsoleEnvironment
  regenerateApiKeyState: GenericDataFetchState<RegenerateApiKeyResponse> | null
}

// Functions

const initialState: DevConsoleState = {
  activeTab: DevConsoleEnvironment.STAGING,
  regenerateApiKeyState: null,
  apiKeysState: {
    error: null,
    data: null,
    isLoading: false,
  },
  chainDetailsState: {
    error: null,
    data: null,
    isLoading: false,
  },
}

export function devConsoleReducer(
  state: DevConsoleState,
  action: DevConsoleAction
): DevConsoleState {
  switch (action.type) {
    case 'set_active_tab':
      return {
        ...state,
        activeTab: action.payload,
      }
    case 'fetch_chain_details_init':
      return {
        ...state,
        chainDetailsState: {
          ...state.chainDetailsState,
          isLoading: true,
          error: null,
        },
      }
    case 'fetch_chain_details_finished':
      return {
        ...state,
        chainDetailsState: {
          ...state.chainDetailsState,
          isLoading: false,
          error: action.error || null,
          data: action.payload,
        },
      }
    case 'fetch_api_keys_init':
      return {
        ...state,
        apiKeysState: {
          ...state.apiKeysState,
          isLoading: true,
          error: null,
        },
      }
    case 'fetch_api_keys_finished':
      return {
        ...state,
        apiKeysState: {
          ...state.apiKeysState,
          isLoading: false,
          error: action.error || null,
          data: action.payload,
        },
      }
    case 'set_new_api_key': {
      const keyTypeKey = action.keyType === 'backend' ? 'storefrontApiKey' : 'omsApiKey'
      const envKey = action.environment

      const defaultEmptyEnvData = {
        storefrontApiKey: '',
        omsApiKey: '',
      }
      const existingEnvData = state.apiKeysState.data?.[envKey] ?? defaultEmptyEnvData
      const currentData = {
        ...state.apiKeysState.data,
        production: {
          ...defaultEmptyEnvData,
          ...state.apiKeysState.data?.production,
        },
        staging: {
          ...defaultEmptyEnvData,
          ...state.apiKeysState.data?.staging,
        },
      }

      const newApiKeyStateData: FetchChainApiKeysResponse = {
        ...currentData,
        [envKey]: {
          ...existingEnvData,
          [keyTypeKey]: action.newKey,
        },
      }

      return {
        ...state,
        apiKeysState: { ...state.apiKeysState, data: newApiKeyStateData },
      }
    }
  }
}

export function useDevConsoleReducer() {
  const reducer = useReducer(devConsoleReducer, initialState)
  return reducer
}

export const DevConsoleContext = createContext<{
  state: DevConsoleState
  dispatch: Dispatch<DevConsoleAction>
}>({
  state: initialState,
  dispatch: () => null,
})
