import './beam-toast.css'

import SlAlertElement from '@shoelace-style/shoelace/dist/components/alert/alert'
import { SlAlert } from '@shoelace-style/shoelace/dist/react'
import React, { forwardRef } from 'react'

export interface BeamToastProps {
  /**
   * Is toast open
   */
  open: boolean
  /**
   * Text shown in toast
   */
  text: string
  /**
   * Name
   */
  name?: string
  /**
   * Is close button shown
   */
  closable?: boolean
  /**
   * style of toast
   */
  variant?: 'success' | 'error'
  /**
   * icon for toast
   */
  icon?: React.ReactNode
  /**
   * Number of milliseconds until the toast will disappear automatically.
   * You can specify a number of milliseconds or `Infinity` if you want the toast to stay on forever.
   */
  duration?: number
  /**
   * Emitted when the alert closes.
   * Use this to reset the toast state after the toast closes itself. This will prevent the toast from re-triggering due to lingering open states.
   * @example
   * <BeamToast onClose={() => setSuccessMessage(null)} />
   */
  onClose: () => void
}

/**
 * Primary UI component for user interaction
 */
export const BeamToast = forwardRef<SlAlertElement, BeamToastProps>(function BeamToast(
  { open, text, closable = false, variant = 'success', duration = 3000, icon, onClose },
  ref
) {
  let variantClassname

  switch (variant) {
    case 'success':
      variantClassname = 'beam--toast--success'
      break

    case 'error':
      variantClassname = 'beam--toast--error'
      break
  }

  return (
    <SlAlert
      className={`beam--toast ${variantClassname}`}
      open={open}
      closable={closable}
      onSlAfterHide={onClose}
      duration={duration}
      ref={ref}>
      <div className="beam--toast--content">
        <div className="beam--toast--icon">{icon}</div>
        <div>{text}</div>
      </div>
    </SlAlert>
  )
})
