import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../../../hooks'
import { TUser } from '../../../../utils/types'
import { BeamSEO } from '../../../root/BeamSEO'
import { DevConsoleContext, useDevConsoleReducer } from '../data-store'
import { fetchChainApiKeys, fetchDevConsoleChainDetails } from '../dev-console-api'
import { DevConsoleNotificationsContextProvider } from '../dev-console-notifications-context'
import { DevConsoleEnvironment } from '../types'
import { DevConsoleTabs } from './components/DevConsoleTabs'
import { DevConsoleInfoContent } from './DevConsoleInfoContent'

export const DevConsoleInfoPage = () => {
  const [state, dispatch] = useDevConsoleReducer()
  const location = useLocation()
  const user: TUser = useBeamSelector(({ user }) => user)

  const { chainDetailsState, apiKeysState } = state
  const envSearchParam = new URLSearchParams(location.search).get('env')

  // determines which tab is active
  useEffect(() => {
    const newTabState =
      envSearchParam === DevConsoleEnvironment.PRODUCTION
        ? DevConsoleEnvironment.PRODUCTION
        : DevConsoleEnvironment.STAGING
    dispatch({
      type: 'set_active_tab',
      payload: newTabState,
    })
  }, [dispatch, envSearchParam])

  // fetch chain details data
  useEffect(() => {
    if (chainDetailsState.isLoading || chainDetailsState.data || chainDetailsState.error) {
      return
    }

    dispatch({
      type: 'fetch_chain_details_init',
    })
    ;(async () => {
      try {
        const data = await fetchDevConsoleChainDetails(user.chainId)
        dispatch({
          type: 'fetch_chain_details_finished',
          payload: data,
        })
      } catch (error: any) {
        console.error(error)
        dispatch({
          type: 'fetch_chain_details_finished',
          error: error.message || 'There was an error fetching your data.',
          payload: null,
        })
      }
    })()
  }, [
    chainDetailsState.data,
    chainDetailsState.error,
    chainDetailsState.isLoading,
    dispatch,
    user.chainId,
  ])

  // fetch API keys
  useEffect(() => {
    if (apiKeysState.isLoading || apiKeysState.data || apiKeysState.error) {
      return
    }

    dispatch({
      type: 'fetch_api_keys_init',
    })
    ;(async () => {
      try {
        const data = await fetchChainApiKeys(user.chainId)
        dispatch({
          type: 'fetch_api_keys_finished',
          payload: data,
        })
      } catch (error: any) {
        console.error(error)
        dispatch({
          type: 'fetch_api_keys_finished',
          error: error.message || 'There was an error fetching your API keys.',
          payload: null,
        })
      }
    })()
  }, [apiKeysState.data, apiKeysState.error, apiKeysState.isLoading, dispatch, user.chainId])

  return (
    <DevConsoleContext.Provider value={{ state, dispatch }}>
      <DevConsoleNotificationsContextProvider>
        <BeamSEO title={'Developer Console'} />
        <main>
          <div className={'bg-charcoal-50 px-5 flex flex-col beam--page-content--no-gutter mb-6'}>
            <div className={'flex flex-row w-full justify-between items-center'}>
              <div className={'w-1/2'}>
                <div>
                  <h1>API Keys & IDs</h1>
                </div>
                <div>
                  <DevConsoleTabs />
                </div>
              </div>

              <div>
                Need help? <Link to={'/contact-support'}>Contact Support</Link>
              </div>
            </div>
          </div>

          <DevConsoleInfoContent />
        </main>
      </DevConsoleNotificationsContextProvider>
    </DevConsoleContext.Provider>
  )
}
