import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import {
  DevConsoleChainDetailsResponse,
  FetchChainApiKeysResponse,
  FetchTransactionsResponse,
  RegenerateApiKeyRequestBody,
  RegenerateApiKeyResponse,
} from './types'

export async function fetchDevConsoleChainDetails(
  chainId: number
): Promise<DevConsoleChainDetailsResponse> {
  const response = await axiosRequest(
    'GET',
    `${getV2ApiUrl()}/dev-console/${chainId}/chain-details`
  )
  return response.data
}

export async function fetchChainApiKeys(chainId: number): Promise<FetchChainApiKeysResponse> {
  const response = await axiosRequest('GET', `${getV2ApiUrl()}/dev-console/${chainId}/keys`)
  return response.data
}

export async function regenerateApiKey(
  chainId: number,
  requestBody: RegenerateApiKeyRequestBody
): Promise<RegenerateApiKeyResponse> {
  const response = await axiosRequest(
    'POST',
    `${getV2ApiUrl()}/dev-console/${chainId}/keys/regenerate`,
    { ...requestBody, isPrimary: true }
  )
  return response.data
}

export async function fetchTransactionLog(chainId: number): Promise<FetchTransactionsResponse> {
  const response = await axiosRequest('GET', `${getV2ApiUrl()}/dev-console/${chainId}/transactions`)
  return response.data
}
