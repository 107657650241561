import { BeamButton } from '../../../../../stories/BeamButton'
import { BeamModal } from '../../../../../stories/BeamModal'
import { ApiKeyLabel } from '../../types'
import { TextFieldWithCopyButton } from './TextFieldWithCopyButton'

interface OmsKeyCopyModalProps {
  newApiKey: string | null
  isOpen: boolean
  effectsAfterClose: () => void
}

export function OmsKeyCopyModal({ isOpen, newApiKey, effectsAfterClose }: OmsKeyCopyModalProps) {
  return (
    <BeamModal
      open={isOpen}
      onCloseCallback={effectsAfterClose}
      disableClickOutside
      body={
        <div className="text-center mb-12 relative">
          <h2 className={'font-primary font-bold mb-4'}>Here is your new {ApiKeyLabel.oms}</h2>
          <TextFieldWithCopyButton label={null} value={newApiKey || ''} />
          <p className={'text-sm italic mt-4 font-primary text-error'}>
            For security purposes, you won’t be able to see this key again. Please copy and store it
            securely before closing this window.
          </p>
        </div>
      }
      footer={
        <div slot={'footer'} className={'space-y-4 pt-4'}>
          <BeamButton variant={'flat_white'} onClick={effectsAfterClose}>
            Close
          </BeamButton>
        </div>
      }
    />
  )
}
