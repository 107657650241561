import { ComponentType, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route } from 'react-router-dom'

import { useBeamSelector } from '../../hooks'
import { EUserType, TUser } from '../../utils/types'
import { validateCanAccessRoute } from '../../utils/validateCanAccessRoute'
import { LoadingPage } from './LoadingPage'

interface PrivateRouteProps {
  component: ComponentType<any>
  exact?: boolean
  location?: any
  path: string
}

export interface LocationStateProps {
  from?: {
    pathname: string
    search: string
    state: Record<string, any>
  }
}

/**
 * A wrapper around base react component that checks whether a user is logged in before passing them along to their component
 */
export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const accessToken = useBeamSelector(({ accessToken }) => accessToken) as string

  const canAccessRoute = validateCanAccessRoute(user)
  const isEngineeringUserType = user?.type === EUserType.Engineering

  return (
    <Route
      {...rest}
      render={props => {
        if (!accessToken || !user?.type) {
          return (
            <Redirect
              to={{
                pathname: `/login`,
                search: props.location.search,
                state: { from: props.location },
              }}
            />
          )
        }

        if (!canAccessRoute) {
          // Send engineering user types directly to dev console
          if (isEngineeringUserType && props.location.pathname === '/') {
            return (
              <Redirect
                to={{
                  pathname: `/dev-console/info`,
                  state: { from: props.location },
                }}
              />
            )
          }

          return (
            <Redirect
              to={{
                pathname: `/404`,
                state: { from: props.location },
              }}
            />
          )
        }

        return (
          <ErrorBoundary fallback={<div />}>
            <Suspense fallback={<LoadingPage />}>
              <Component {...props} />
            </Suspense>
          </ErrorBoundary>
        )
      }}
    />
  )
}
