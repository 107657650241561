import cx from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { fetchStripeData } from '../../../api/stripeApi'
import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useFetchEnrollmentData } from '../../../hooks/useFetchEnrollmentData'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCopyLink } from '../../../stories/BeamCopyLink'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { BeamToggle } from '../../../stories/BeamToggle'
import { axiosRequest } from '../../../utils/axiosRequest'
import { formatDate } from '../../../utils/helpers/formatDate'
import { EUserType, TUser } from '../../../utils/types'
import { APIError } from '../../root/APIError'
import { BeamSEO } from '../../root/BeamSEO'
import { PageNotFound } from '../../root/PageNotFound'
import { TStateWithLoading } from '../OverviewPage/OverviewPage.types'
import {
  PaymentsSetupModals,
  PaymentsSetupModalsContext,
  PaymentsSetupModalsEnums,
} from '../PaymentsModal'
import { CustomerPaymentDataFromStripe, fetchPaymentInfo } from '../PaymentsModal/PaymentsModal.api'
import { autopaySuccessful } from '../PaymentsModal/PaymentsModalUtils'
import { PaypalGivingFundStatusEnums } from '../PaypalGivingFundPage'
import $$ from './profile-page.module.css'
import { RedirectToPPGF } from './RedirectToPPGF'

interface TAccountData {
  currentPassword?: string
  newPassword1?: string
  newPassword2?: string
  name: string
  email: string
  useTwoFactor: boolean
}

const updateAccount = async (requestData: TAccountData) => {
  const payload = {
    ...requestData,
    email: requestData.email === '' ? undefined : requestData.email,
  }
  return await axiosRequest('put', `${API_BASE_PATH_PORTAL}/v2/users`, payload)
}

interface ProfilePageProps {
  fetchStripeDataMethod?: typeof fetchStripeData
}

export const ProfilePage = ({ fetchStripeDataMethod = fetchStripeData }: ProfilePageProps) => {
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const ppgfModalUrlParam = urlParams.get('ppgf_modal')

  const user = useBeamSelector(({ user }) => user) as TUser
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const initialAccountData = {
    name: [user.firstName || '', user.lastName || ''].join(' '),
    email: user?.email || '',
    useTwoFactor: user?.useTwoFactor || false,
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  }
  const initialDataState = { data: null, loading: false, error: '' }

  const [accountData, setAccountData] = useState<TAccountData>(initialAccountData)
  const [activeModal, setActiveModal] = useState<PaymentsSetupModalsEnums | null>(null)
  const [stripeData, setStripeData] = useState<TStateWithLoading>(initialDataState)
  const [invoiceEmails, setInvoiceEmails] = useState({
    invoiceEmail: '',
    invoiceCcEmails: [] as string[],
  })
  const [paymentMethodInfo, setPaymentMethodInfo] =
    useState<CustomerPaymentDataFromStripe | null>(null)

  const canSeeAutopayFeatures = !!user?.partner?.canSeeAutopayFeatures

  const loadStripeData = useCallback(() => {
    if (!user.chainId) return

    setStripeData({ data: stripeData, loading: true, error: null })

    fetchStripeDataMethod(user?.chainId)
      .then(stripeData => {
        setStripeData({ data: stripeData, loading: false, error: null })
      })
      .catch(error => setStripeData({ data: null, loading: false, error: error as any }))
  }, [fetchStripeDataMethod, stripeData, user.chainId])

  // Display ppgf modal if directly linked to it
  useEffect(() => {
    if (!canSeeAutopayFeatures) return

    if (ppgfModalUrlParam) {
      setActiveModal(PaymentsSetupModalsEnums.setupPpgfAutoPay)
    }
  }, [canSeeAutopayFeatures, ppgfModalUrlParam])

  useEffect(() => {
    setInvoiceEmails({
      invoiceEmail: user?.partner?.invoiceEmail || '',
      invoiceCcEmails: user?.partner?.invoiceCcEmails || ([] as string[]),
    })
  }, [user?.partner?.invoiceEmail, user?.partner?.invoiceCcEmails])

  // fetch stripeData
  useEffect(() => {
    if (!canSeeAutopayFeatures) return
    if (!user?.chainId) return

    const { data, loading, error } = stripeData
    if (data || loading || error) return

    loadStripeData()
  }, [canSeeAutopayFeatures, loadStripeData, stripeData, user?.chainId])

  // Show success toast if query params are present after redirect
  useEffect(() => {
    const failureMessage = 'There was a problem saving your changes'

    autopaySuccessful({
      history,
      changeActiveModal: () => changeActiveModal(null),
      isPaymentMethodUpdate: true,
    })
      .then(({ success, skipped }) => {
        if (skipped) return
        if (!success) setErrorMessage(failureMessage)

        fetchPaymentMethodInfo()
        setOpenToast(true)
      })
      .catch(error => {
        console.error(error)
        setErrorMessage(error.message || failureMessage)
        setOpenToast(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  // Fetch paymentMethodInfo on mount
  useEffect(() => {
    if (!paymentMethodInfo?.paymentMethod?.type) {
      fetchPaymentMethodInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target as HTMLInputElement
    if (target?.name) {
      const newAccountData = {
        ...accountData,
        [target.name]: target.value,
      }

      setAccountData(newAccountData)
    }
  }

  const toggleState = () => {
    setIsEditable(!isEditable)
  }

  const submitData = async (event: any) => {
    try {
      event.preventDefault()

      await updateAccount(accountData)
      setErrorMessage(null)
      setOpenToast(true)
      setIsEditable(false)

      // TODO: Once we set up refresh tokens, dispatch(setUser()) on the newly centralized user object from the refresh tokens PR.
      // Check out PPOR-1501
      setTimeout(() => location.reload(), 5000)
    } catch (error: any) {
      const res = JSON.stringify(
        (error && error.response && error.response.data && error.response.data.error) ||
          (error && error.message) ||
          error
      )
      setErrorMessage(res)
      setOpenToast(true)
    }
  }

  function fetchPaymentMethodInfo() {
    fetchPaymentInfo()
      .then(data => setPaymentMethodInfo(data))
      .catch((error: any) => {
        console.log('FetchPaymentInfoFailed', error)
      })
  }

  function changeActiveModal(newModal: PaymentsSetupModalsEnums | null) {
    if (newModal !== activeModal) {
      setActiveModal(newModal)
    }
  }

  function afterModalCloseHandler(closedModal: PaymentsSetupModalsEnums | null) {
    // The onCloseCallback event gets triggered after the context has been updated, causing modals to close when transitioning from one modal to another.
    if (activeModal && activeModal !== closedModal) {
      return
    }

    setActiveModal(null)
  }

  function toggleTwoFactorAuth() {
    const newAccountData = {
      ...accountData,
      useTwoFactor: !accountData.useTwoFactor,
    }
    setAccountData(newAccountData)
  }

  // callback passed into modals for displaying toast after operations finish
  function displayToastCallback(errorMessage?: string) {
    setOpenToast(false)

    if (errorMessage) {
      setErrorMessage(errorMessage)
      setOpenToast(true)
      return
    }

    setErrorMessage(null)
    setOpenToast(true)
    // reload stripeData after success
    loadStripeData()
  }

  const canSeeBillingDetails = [EUserType.Executive].includes(user?.type)
  if (loadingStates?.user?.loading) return <div>One moment please...</div>
  if (loadingStates?.user?.error) return <APIError error={loadingStates.user.error} />
  if (
    ![
      EUserType.Executive,
      EUserType.Engineering,
      EUserType.Marketing,
      EUserType.Finance,
      EUserType.Staff,
    ].includes(user?.type)
  ) {
    return <PageNotFound />
  }

  return (
    <PaymentsSetupModalsContext.Provider
      value={{
        activeModal,
        changeActiveModal,
      }}>
      <BeamSEO title={`Manage Account`} />

      <PaymentsSetupModals
        activeModal={activeModal}
        afterModalCloseHandler={afterModalCloseHandler}
        invoiceEmails={invoiceEmails}
        isPaypalSetup={user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled}
        partnerId={user?.partnerId as number}
        profilePageToastHandler={displayToastCallback}
      />
      <h1 className="beam--heading--1">Manage Account</h1>
      <p>
        Need help? <Link to={`/contact-support`}>Contact Us</Link>
      </p>

      <div className="grid grid-cols-2">
        <div>
          <h2 className="beam--heading--2">General Information</h2>
          {isEditable ? (
            <EditProfilePage
              submitData={submitData}
              toggleAuth={toggleTwoFactorAuth}
              captureValue={captureValue}
              accountData={accountData}
            />
          ) : (
            <ViewProfilePage user={user} toggleState={toggleState} />
          )}
        </div>
        {canSeeAutopayFeatures && canSeeBillingDetails && (
          <div>
            <h2 className="beam--heading--2">Payment Information</h2>
            <PaymentInfoSection
              autopay={stripeData?.data?.autopay}
              changeActiveModal={changeActiveModal}
              user={user}
              paymentMethodInfo={paymentMethodInfo}
            />
          </div>
        )}
      </div>

      <BeamToast
        onClose={() => setOpenToast(false)}
        open={openToast}
        closable={true}
        text={errorMessage ? errorMessage : 'Changes Saved!'}
        variant={errorMessage ? 'error' : 'success'}
        icon={errorMessage ? null : <span>&#127881;</span>}
      />
    </PaymentsSetupModalsContext.Provider>
  )
}

const EditProfilePage = ({
  submitData,
  toggleAuth,
  captureValue,
  accountData,
}: {
  submitData: (e: any) => Promise<void>
  toggleAuth: () => void
  captureValue: BeamInputChangeEvent
  accountData: TAccountData
}) => {
  return (
    <form onSubmit={submitData} className="max-w-xl space-y-6">
      <BeamTextfield
        name="name"
        label="Name"
        variant="small"
        onChange={captureValue}
        value={accountData.name}
      />
      <BeamTextfield
        name="email"
        label="Email"
        variant="small"
        onChange={captureValue}
        value={accountData.email}
      />
      <BeamTextfield
        name="currentPassword"
        label="Current Password"
        variant="small"
        onChange={captureValue}
        type={'password'}
      />
      <BeamTextfield
        name="newPassword1"
        label="New Password"
        variant="small"
        onChange={captureValue}
        type={'password'}
      />
      <BeamTextfield
        name="newPassword2"
        label="New Password (Again)"
        variant="small"
        onChange={captureValue}
        type={'password'}
      />
      <div className={$$.twoFactorAuthLabel}>Enable Two Factor Authentication?</div>
      <BeamToggle
        name="2FA"
        checked={accountData.useTwoFactor}
        onChange={() => toggleAuth()}
        label={accountData.useTwoFactor ? 'on' : 'off'}
      />
      <BeamButton label="Save Changes" variant="elevated" type="submit" />
    </form>
  )
}

const ViewProfilePage = ({ user, toggleState }: { user: TUser; toggleState: () => void }) => {
  return (
    <div className="space-y-6">
      <div className="two-column-grid-wrapper">
        <div>
          <div>
            <h3 className="mb-0 beam--heading--3">Name</h3>
            <p className="-mt-4 beam--paragraph--small">
              {user.firstName
                ? user.lastName
                  ? user.firstName + ' ' + user.lastName
                  : user.firstName
                : user.lastName
                ? user.lastName
                : 'No name set'}
            </p>
          </div>
          <div>
            <h3 className="mb-0 beam--heading--3">Email</h3>
            <p className="-mt-4 beam--paragraph--small">{user?.email || 'No email set'}</p>
          </div>
          <div>
            <h3 className="mb-0 beam--heading--3">Password</h3>
            <p className="-mt-4 beam--paragraph--small">************</p>
          </div>
          <div>
            <h3 className=" beam--heading--3">Two factor Authentication</h3>
            <BeamToggle
              disabled={true}
              label={user?.useTwoFactor ? 'on' : 'off'}
              checked={!!user?.useTwoFactor}
            />
          </div>
          <div>
            <BeamButton
              className="max-w-xs mt-4"
              label="Edit Account Information"
              variant="white"
              onClick={() => toggleState()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const PaymentInfoSection = ({
  autopay,
  changeActiveModal,
  user,
  paymentMethodInfo,
}: {
  autopay: boolean
  changeActiveModal: (n: PaymentsSetupModalsEnums) => void
  user: TUser
  paymentMethodInfo: CustomerPaymentDataFromStripe | null
}) => {
  const { enrollmentData } = useFetchEnrollmentData()
  const featureFlags = useFeatureFlags()
  const showCampaignResetDate = enrollmentData?.enrollmentStatus === 'unlimited'

  return (
    <div>
      <div>
        <h2 className="mb-6 beam--heading--thin--2">Autopay for Beam Fees</h2>
        <div className="-mt-2">
          {autopay ? (
            <div className="flex flex-col space-y-4">
              <p className="text-base leading-5 font-secondary text-light-grey-1">
                Autopay for Beam Fees is setup! Your Beam fees will be automatically paid each month
              </p>
              <span
                className={cx('font-secondary text-[14px]', $$.link)}
                onClick={() => {
                  const isCard = paymentMethodInfo?.paymentMethod.type === 'card'
                  changeActiveModal(
                    isCard
                      ? PaymentsSetupModalsEnums.viewPaymentDetails
                      : PaymentsSetupModalsEnums.paymentDetails
                  )
                }}>
                Edit Payment Details
              </span>
              <p className="text-base leading-5 font-secondary text-light-grey-1">
                Please contact your Client Strategy Lead if you would like to disconnect Autopay for
                Beam Fees
              </p>
            </div>
          ) : (
            <p className="text-base leading-4 font-secondary text-charcoal-600">
              Set up Autopay for Beam Fees from the Invoices page
            </p>
          )}
        </div>
      </div>
      {autopay && (
        <div>
          <h2 className="mb-6 beam--heading--thin--2">Invoice Email(s)</h2>
          <div className="-mt-5">
            <span
              className={cx('font-secondary text-[14px]', $$.link)}
              onClick={() => changeActiveModal(PaymentsSetupModalsEnums.collectEmail)}>
              View or Edit Billing Details
            </span>
          </div>
        </div>
      )}
      {!user.partner.inKindDonations.displayInKindDonations && (
        <div>
          <h2 className="mb-6 beam--heading--thin--2">
            Autopay for Donations
            <BeamCopyLink />
          </h2>
          <div className="-mt-4 text-base">
            {user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled ? (
              <>
                <p className="text-base leading-4 font-secondary text-light-grey-1">
                  Donations owed to nonprofits on Paypal Giving Fund will be automatically processed
                  each quarter
                </p>
                <br />
                <RedirectToPPGF />
              </>
            ) : (
              <>
                <p className="text-base leading-4 font-secondary text-charcoal-600">
                  Set up Autopay for Donations using the link below
                </p>
                <br />
                <p className="text-base leading-4 font-secondary">
                  <Link to={`/paypal-giving-fund`}>Set up Autopay for Donations</Link>
                </p>
              </>
            )}
          </div>
        </div>
      )}
      {featureFlags['enhanced-features'] &&
        enrollmentData &&
        enrollmentData?.enrollmentStatus !== 'ineligible' && (
          <div>
            <div>
              <h2 className="mb-5 beam--heading--thin--2">Account Plan</h2>
            </div>
            <div className="-mt-4">
              <p className={'text-base leading-5 font-secondary text-light-grey-1'}>
                Plan: {enrollmentData.enrollmentStatusDisplayName}
              </p>
              {showCampaignResetDate && (
                <p className={'text-base leading-5 font-secondary text-light-grey-1'}>
                  Campaign Reset Date: {formatDate(enrollmentData?.renewalDate, 'MM/dd/yyyy')}
                </p>
              )}
            </div>
          </div>
        )}
    </div>
  )
}
