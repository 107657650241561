import { EUserType } from './types'

const accessScopesMap: { [key in EUserType]: string[] | null } = {
  [EUserType.Engineering]: [
    '/dev-console',
    '/contact-support',
    '/404',
    '/profile',
    '/login',
    '/logout',
    '/salesforce/authorize',
    `/salesforce/authorize-callback`,
    `/salesforce/error`,
    `/yotpo/authorize`,
    `/yotpo/authorize-callback`,
    `/yotpo/error`,
    `/shopify-public-app/upgrade`,
    `/shopify-public-app/connect`,
    `/shopify-public-app/associate`,
  ],
  Admin: null,
  Super: null,
  Executive: null,
  Marketing: null,
  Finance: null,
  Staff: null,
}

export function getUserAccessScopes(userType: EUserType): string[] | null {
  return accessScopesMap[userType]
}
