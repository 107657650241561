import { SlCopyButton, SlIconButton } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { isNil } from 'lodash'

import { BeamTextfield } from '../../../../../stories/BeamTextfield'

interface TextFieldWithCopyButtonProps {
  label: string | null
  value: string | number
  /**
   * Function triggered when the "reload" button is clicked. "Reload" button is hidden when this prop is absent.
   */
  onClickReload?: () => void
  /**
   * Hides the copy button
   */
  disableCopy?: boolean
}

export const TextFieldWithCopyButton = ({
  label,
  value,
  onClickReload,
  disableCopy = false,
}: TextFieldWithCopyButtonProps) => {
  const valueString = String(value)

  return (
    <div className={'flex flex-row justify-between items-center'}>
      {label && <span className={'text-sky-800 font-normal'}>{label}</span>}
      <div className={cx({ 'w-[63%]': !!label, 'w-full': !label })}>
        <BeamTextfield name={'chainId'} readonly={true} value={value ? String(value) : ''}>
          <div slot={'suffix'}>
            {!disableCopy && (
              <SlCopyButton
                copyLabel={'Copy key to clipboard'}
                value={valueString}
                style={{ color: 'var(--beam-color--black)' }}
              />
            )}
            {!isNil(onClickReload) && (
              <SlIconButton
                name={'reload'}
                library={'system'}
                onClick={onClickReload}
                style={{ color: 'var(--beam-color--black)' }}
              />
            )}
          </div>
        </BeamTextfield>
      </div>
    </div>
  )
}
