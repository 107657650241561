import { useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useBeamSelector } from '../../../hooks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamInfoPanel } from '../../../stories/BeamInfoPanel'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../stories/BeamModal'
import { axiosRequest } from '../../../utils/axiosRequest'
import { TUser } from '../../../utils/types'
import { PaymentsSetupModalsEnums } from '../PaymentsModal'

const getApprovalLink = async () => {
  const data = await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/invoices/paypal/billing-agreement/token`
  )

  return data.data
}

interface PpgfPaymentsModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums) => void
}

export const PpgfPaymentsModal = ({ open, afterCloseHandler }: PpgfPaymentsModalProps) => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const [approvalLink, setApprovalLink] = useState('')
  const [error, setError] = useState('')
  const [loadingPaypal, setLoadingPaypal] = useState(false)

  if (user?.type !== 'Executive') return null

  async function navigateToPaypal() {
    setLoadingPaypal(true)

    try {
      const generatedLink = await getApprovalLink()

      setApprovalLink(generatedLink)
      setLoadingPaypal(false)

      window.location.href = generatedLink
    } catch (error: any) {
      setLoadingPaypal(false)
      setError(error.message)
    }
  }

  if (loadingPaypal && !approvalLink) {
    return (
      <div>
        <h2 className="mb-4 text-base leading-9 text-center font-primary">
          Getting Paypal Link...
        </h2>
        <BeamLoadingIndicator />
      </div>
    )
  }

  if (!error && approvalLink) {
    return (
      <p className="mb-4 text-base leading-9 text-center font-primary">
        If you are not redirected, please follow <a href={approvalLink}>this link</a> to complete
        your set up with PayPal.
      </p>
    )
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <BeamModal
      label="Autopay for donations"
      subheading="Automate donations to support participating nonprofit partners with Beam's partner, PayPal Giving Fund"
      open={open}
      onCloseCallback={() => afterCloseHandler(PaymentsSetupModalsEnums.setupPpgfAutoPay)}
      body={
        <div>
          <div>
            <p className="mb-5 text-base font-secondary">
              There are just three steps to automating donations:
            </p>
            <ol className="pl-2 mb-5 space-y-4 list-decimal list-inside font-secondary">
              <li className="">
                Login to PayPal and add your preferred payment method, bank account or credit card
                (or create your PayPal account)
              </li>
              <li>Accept PayPal’s agreement to authorize donation autopay</li>
              <li>
                Beam will initiate a donation every 90 days to benefit applicable nonprofit with our
                partner, PayPal Giving Fund
              </li>
              <li>
                You’ll receive a donation receipt from PayPal Giving Fund that you can use to claim
                deductions
              </li>
            </ol>
          </div>

          <BeamInfoPanel>
            Automatic payments will only be processed for nonprofits that are registered with PayPal
            Giving Fund. Each quarter, when we trigger donations to support participating nonprofit
            partners, we will also send you an invoice with donation instructions for nonprofits not
            yet enrolled with PayPal Giving Fund.
          </BeamInfoPanel>

          <BeamButton
            label="Connect My Paypal Giving Fund"
            variant="elevated"
            onClick={navigateToPaypal}
            className="mb-6"
          />

          <p className="font-secondary text-[12px] leading-4 text-center pb-4">
            Donations will be made to PayPal Giving Fund (PPGF), an IRS-registered 501(c)(3)
            nonprofit organization. PPGF receives the donation and distributes the appropriate
            amount to each of your partner nonprofits, subject to its terms. Making donations
            through PayPal Giving Fund also enables us to manage applicable compliance for you. Your
            team will receive donation receipts from PayPal Giving Fund confirming donations have
            been successfully processed. Beam does not have access to your payment information.
          </p>
        </div>
      }
      disableClose={loadingPaypal}
    />
  )
}
