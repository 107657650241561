import { isNil } from 'lodash'

import { dollarFormat } from '../../../../../utils/root'
import { FetchTransactionsResponseObject } from '../../types'

export const EMPTY_VALUE_PLACEHOLDER = '---'

export function convertToCurrency(value: number | null) {
  return isNil(value) ? EMPTY_VALUE_PLACEHOLDER : dollarFormat(value, 2)
}

export function sanitizeTransactionData(
  transaction: FetchTransactionsResponseObject
): FetchTransactionsResponseObject {
  const newTxn: any = { ...transaction }

  for (const [key, value] of Object.entries(transaction)) {
    if (['includesSubscription', 'isDeleted', 'isTest'].includes(key)) {
      // skip boolean items
      continue
    }

    if (isNil(value)) {
      newTxn[key] = EMPTY_VALUE_PLACEHOLDER
    }
  }

  return newTxn
}
