import { delay } from 'lodash'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { BeamAlert } from '../../../../stories/BeamAlert/BeamAlert'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { CenteredContent } from '../../../root/CenteredContent'
import { DevConsoleContext } from '../data-store'
import { ApiKeyLabel, ApiKeyType, DevConsoleEnvironment } from '../types'
import { DevConsoleTable } from './components/DevConsoleResourceIdTable'
import {
  ConfirmationModalDataProp,
  KeyRegenerationConfirmationModal,
} from './components/KeyRegenerationConfirmationModal'
import { OmsKeyCopyModal } from './components/OmsKeyCopyModal'
import { TextFieldWithCopyButton } from './components/TextFieldWithCopyButton'

const TableTitle = ({ title }: { title: string }) => {
  return (
    <h3 className={'text-sky-800 font-primary text-md font-medium leading-8 mb-2 mt-0'}>{title}</h3>
  )
}

export const DevConsoleInfoContent = () => {
  const { state } = useContext(DevConsoleContext)
  const { chainDetailsState, apiKeysState, activeTab } = state
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalDataProp | null>(null)
  const [omsKeyCopyModal, setOmsKeyCopyModal] = useState<{ newApiKey: string } | null>(null)

  const chainDetails =
    activeTab === 'production'
      ? chainDetailsState?.data?.production
      : chainDetailsState?.data?.staging
  const chainId = chainDetails?.chainId
  const apiKeys =
    activeTab === 'production' ? apiKeysState?.data?.production : apiKeysState?.data?.staging

  function handleOpenConfirmationModal(data: ConfirmationModalDataProp) {
    setConfirmationModal(data)
  }

  function handleCloseConfirmationModal(newKey?: string) {
    // show the `omsKeyCopyModal` after a new OMS key is generated
    if (newKey) {
      setConfirmationModal(null)
      delay(
        newApiKey => {
          setOmsKeyCopyModal({
            newApiKey,
          })
        },
        500,
        newKey
      )
    }

    setConfirmationModal(null)
  }

  const hasEmptyChainDetails =
    !chainDetailsState.isLoading && !chainDetailsState.data?.staging.stores.length

  if (hasEmptyChainDetails && activeTab === DevConsoleEnvironment.STAGING) {
    return (
      <section className={'max-w-[720px]'}>
        <h2>Staging Account Not Configured</h2>
        <p className={'font-primary text-md'}>
          Your account has not been configured for Staging use. Please{' '}
          <Link to={'/contact-support?prefill=staging_account'}>contact support</Link> to request a
          Staging account.
        </p>
      </section>
    )
  }

  return (
    <>
      <KeyRegenerationConfirmationModal
        isOpen={!!confirmationModal}
        effectsAfterClose={handleCloseConfirmationModal}
        data={confirmationModal}
      />

      <OmsKeyCopyModal
        isOpen={!!omsKeyCopyModal}
        effectsAfterClose={() => setOmsKeyCopyModal(null)}
        newApiKey={omsKeyCopyModal?.newApiKey || null}
      />

      <section>
        {chainDetailsState.isLoading ? (
          <CenteredContent>
            <BeamLoadingIndicator />
          </CenteredContent>
        ) : (
          <div className={'flex flex-col max-w-[720px]'}>
            {activeTab === DevConsoleEnvironment.PRODUCTION && (
              <BeamAlert variant={'danger'} className={'mb-6'}>
                <div className={'pl-2 py-2'}>
                  <div className={'text-md'}>Production Data</div>
                  <div className={'font-normal text-sm'}>
                    You are now viewing and editing production data
                  </div>
                </div>
              </BeamAlert>
            )}
            <div className={'mb-[56px] space-y-6'}>
              <h2 className={'mt-0'}>API Keys</h2>

              <TextFieldWithCopyButton
                label={ApiKeyLabel.storefront}
                value={apiKeys?.storefrontApiKey || ''}
                onClickReload={() => {
                  handleOpenConfirmationModal({
                    keyLabel: ApiKeyLabel.storefront,
                    body: {
                      environment: activeTab,
                      keyType: ApiKeyType.backend,
                      oldKeyPrefix: apiKeys?.storefrontApiKey.split('.')[0] || '',
                    },
                  })
                }}
              />
              <TextFieldWithCopyButton
                label={ApiKeyLabel.oms}
                value={apiKeys?.omsApiKey || ''}
                onClickReload={() => {
                  handleOpenConfirmationModal({
                    keyLabel: ApiKeyLabel.oms,
                    body: {
                      environment: activeTab,
                      keyType: ApiKeyType.orderSync,
                      oldKeyPrefix: apiKeys?.omsApiKey.split('.')[0] || '',
                    },
                  })
                }}
                disableCopy
              />
            </div>

            <h2>Chain & Site Details</h2>

            <div className={'mb-6'}>
              <TextFieldWithCopyButton label={'Chain ID'} value={chainId || ''} />
            </div>

            <div className={'mb-[50px]'}>
              <TableTitle title={`Sites`} />
              <DevConsoleTable
                data={chainDetails?.stores || []}
                tableOptions={{
                  resourceNameColumnLabel: 'Site Name',
                  resourceIdColumnLabel: 'Store ID',
                }}
              />
            </div>

            {chainDetails?.stores.map(store => {
              return (
                <div key={store.id + store.name} className={'mb-8'}>
                  <TableTitle title={`${store.name}: Nonprofit IDs`} />
                  <DevConsoleTable
                    data={store.nonprofits}
                    tableOptions={{
                      resourceNameColumnLabel: 'Nonprofit Name',
                      resourceIdColumnLabel: 'Nonprofit ID',
                    }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </section>
    </>
  )
}
