import './beam-alert.css'

import SlAlertElement from '@shoelace-style/shoelace/dist/components/alert/alert'
import { SlAlert, SlIcon } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import * as React from 'react'

type BeamAlertProps = {
  /**
   * Whether alert banner should be full-width or fit content
   */
  inline?: boolean
  /**
   * Icon element to show on left side of banner, set to null to remove
   */
  icon?: React.ReactElement | null
} & React.ComponentProps<typeof SlAlert>

const defaultIcons: Record<NonNullable<BeamAlertProps['variant']>, string> = {
  primary: 'info',
  success: 'check_circle',
  neutral: 'info',
  warning: 'warning_round',
  danger: 'error_outline_outline',
}

/**
 * Alert banner
 * Wraps SlAlert from Shoelace, with Beam styling applied
 */
export const BeamAlert = React.forwardRef<SlAlertElement, BeamAlertProps>(function BeamAlert(
  {
    className,
    open = true,
    inline,
    variant = 'primary',
    icon = <SlIcon name={defaultIcons[variant]} />,
    children,
    ...props
  },
  ref
) {
  const iconSlot = icon
    ? React.cloneElement(icon, {
        slot: 'icon',
      })
    : null
  return (
    <SlAlert
      {...props}
      ref={ref}
      variant={variant}
      open={open}
      className={cx(className, 'beam--alert', `beam--alert--${variant}`, {
        'inline-block': inline,
        'beam--alert--has-icon': iconSlot != null,
      })}>
      {iconSlot}
      {children}
    </SlAlert>
  )
})
